const Sponsor = () => {
  return (
    <div className="w-full pt-10">
      <h2 className="text-3xl font-bold text-indigo-700">Our Sponsors</h2>
      <h3 className="text-xl font-bold text-blue-900">
        A huge thank you to all our tour sponsors
      </h3>
      <div className="flex gap-6 justify-around flex-wrap w-full px-2 py-6 mt-6 mx-auto items-center justify-items-center">
        <div className="w-20 h-20 rounded-full overflow-hidden">
          <a
            href="https://www.facebook.com/thehitchingpostdumfries"
            target="_blank"
            rel="noreferrer"
          >
            <picture>
              <source srcSet={`images/sponsors/hitching.webp`} />
              <img
                src={`images/sponsors/hitching.jpg`}
                alt="The Hitching Post"
              />
            </picture>
          </a>
        </div>
        <div className="w-52">
          <a
            href="https://www.scottishpolicecu.co.uk/"
            target="_blank"
            rel="noreferrer"
          >
            {' '}
            <picture>
              <source srcSet={`images/sponsors/spcu.webp`} />
              <img
                src={`images/sponsors/spcu.jpg`}
                alt="Scottish Police Credit Union"
              />
            </picture>
          </a>
        </div>
        <div className="w-32">
          <a href="https://www.sprapol.co.uk/" target="_blank" rel="noreferrer">
            <picture>
              <source srcSet={`images/sponsors/spra.webp`} />
              <img
                src={`images/sponsors/spra.jpg`}
                alt="Scottish Police Recreation Association"
              />
            </picture>
          </a>
        </div>
        <div className="w-32">
          <picture>
            <source srcSet={`images/sponsors/atwals.webp`} />
            <img src={`images/sponsors/atwals.jpg`} alt="Atwals" />
          </picture>
        </div>
        <div className="w-32">
          <a
            href="https://www.thomsonsfoodservice.co.uk/"
            target="_blank"
            rel="noreferrer"
          >
            <picture>
              <source srcSet={`images/sponsors/thomsons.webp`} />
              <img
                src={`images/sponsors/thomsons.jpg`}
                alt="Thomsons Food Service"
              />
            </picture>
          </a>
        </div>
        <div className="w-36">
          <a href="https://www.vigilant.uk/" target="_blank" rel="noreferrer">
            <picture>
              <source srcSet={`images/sponsors/vigilant.webp`} />
              <img
                src={`images/sponsors/vigilant.jpg`}
                alt="Vigilant Security"
              />
            </picture>
          </a>
        </div>
        <div className="w-36">
          <a
            href="https://www.heatingcaredumfries.co.uk/"
            target="_blank"
            rel="noreferrer"
          >
            <picture>
              <source srcSet={`images/sponsors/heatingcare.webp`} />
              <img
                src={`images/sponsors/heatingcare.jpg`}
                alt="Heating Care Dumfries"
              />
            </picture>
          </a>
        </div>
        <div className="w-36">
          <a href="https://yaffy.co.uk/" target="_blank" rel="noreferrer">
            <picture>
              <source srcSet={`images/sponsors/yaffy.webp`} />
              <img src={`images/sponsors/yaffy.webp`} alt="Yaffy" />
            </picture>
          </a>
        </div>
      </div>
      <p className="text-lg font-semibold">
        Want to see your company logo here? For more info contact{' '}
        <a
          href="mailto:sponsors@unitytour.scot"
          className="text-blue-900 font-semibold tracking-wider"
        >
          sponsors@unitytour.scot
        </a>
      </p>
    </div>
  );
};

export default Sponsor;
