import { useEffect } from 'react';

const Notfound = () => {
  useEffect(() => {
    document.title = 'Police Unity Tour Scotland - Not Found';
  }, []);

  return (
    <>
      <h1 className="text-2xl md:text-4xl font-bold text-blue-900">
        Police Unity Tour Scotland.
      </h1>
      <h2 className="text-xl md:text-3xl font-bold text-indigo-700">
        <span className="font-extrabold">404 -</span> Page Not Found.
      </h2>
    </>
  );
};

export default Notfound;
