import { Disclosure, Transition } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/solid';
import ReactHtmlParser from 'html-react-parser';

const FaqData = require('../data/faq');

const FaqList = () => {
  const disclosureItems = FaqData.map((faq, i) => (
    <Disclosure key={i}>
      {({ open }) => (
        <>
          <Disclosure.Button className="flex w-full justify-between text-left py-2 text-md font-medium text-indigo-800 rounded-lg sm:text-lg">
            <span>{faq.question}</span>
            <ChevronUpIcon
              className={`${
                open ? 'transform rotate-180 duration-300' : ''
              } w-5 h-5 text-indigo-700`}
            />
          </Disclosure.Button>
          <Transition
            enter="transition duration-500 ease-in-out"
            enterFrom="transform scale-95 opacity-0 "
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-200 ease-in-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Disclosure.Panel className="pt-2 pb-2 max-w-2xl">
              {ReactHtmlParser(faq.answer)}
            </Disclosure.Panel>
          </Transition>
        </>
      )}
    </Disclosure>
  ));

  return (
    <div className="w-full pt-10">
      <h2 id="faq" className="text-3xl font-bold text-indigo-700">
        Frequently Asked Questions
      </h2>
      <div className="w-full px-2 mt-6 mx-auto bg-blue-100">
        {disclosureItems}
      </div>
    </div>
  );
};

export default FaqList;
