module.exports = [
  {
    riderName: 'Scott McCreadie',
    position: 'Chapter Lead',
    ridingFor: 'Alan McMurray',
    eow: '04/02/2006',
    image: '/images/profile/scott_mccreadie',
    sponsorName: 'Scott',
    justGiving: 'https://www.justgiving.com/page/scott-mccreadie-1705076857432',
    bio: `I am Scott McCreadie - this is my seventh year taking part in the Police Unity Tour.

    This year is even more special as I take up the privileged role as Lead for the Scottish Chapter.
    
    We work hard in memory of our colleagues and even manage banter despite cycling up Shap!
    The best part is raising money for COPS and knowing the difference it makes to those who have lost a loved one.   
    
    We have a great team this year with a good mix of survivors, serving and retired officers, support staff and our fantastic support team.
    
    This year I am riding in memory of PC Alan McMurray.  'Alan was a fellow Traffic Cop with Lothian and Borders Police, who tragically died while dealing with a RTC.
    
    Please follow our team on Social Media and support in any way you can, thank you.`,
  },
  {
    riderName: 'Jaclyn Irving',
    position: 'Rider',
    ridingFor: 'Edward Thomson',
    eow: '13/01/1982',
    image: '',
    sponsorName: 'Jaclyn',
    justGiving: 'https://www.justgiving.com/page/jaclyn-irving-2024',
    bio: `I've been a Police Officer for over 13 years, joining Dumfries and Galloway Constabulary and then Police Scotland.
    This will be my first Unity Tour and I'm honoured to be riding in memory of Sergeant Edward Thomson. Sergeant Thomson died in January 1982 when his Police vehicle was involved in a collision.
    At the time he was responding to an emergency call, something we all do as a matter of course in our daily work lives but never think that this could be our last one. 
    Becoming a mum midway through my service has changed my not only my view on life but on the job, it made me painfully aware of who would be left behind should I not make it home from a shift. 
    Thats where Care of Police Survivors comes in, this incredible charity helps Police families of serving officers who have died on duty. 
    No one should leave for work and not come home but if that was to happen its a comfort to know they would have an incredible team to help get them through.

    I'm looking forward to arriving at the Arboretum and seeing everyone who has come together, trained and worked hard to raise funds for this charity. I'm least looking forward to the wind, rain and sore legs. I'm very excited to have my husband, an ex Police Officer, taking up the important role of support to the team as we take on this epic cycle. He is least looking forward to hearing me moan about the rain, wind and sore legs!`,
  },
  {
    riderName: 'Jiles Alcock',
    position: 'Rider',
    ridingFor: 'John Alcock',
    eow: '07/10/2017',
    image: '/images/profile/jiles',
    sponsorName: 'Jiles',
    justGiving: 'https://www.justgiving.com/page/jiles-alcock-1704229612544',
    bio: `I am a mechanical engineer working in the nuclear defence industry. 
    I am riding for my older brother, John Alcock, who was a Royal Marines Commando marksman, and then served as a PC with Grampian Police.  
    He was lost to us following a road collision on duty in August 2003 and spending 14 years in a coma (EOW 7th October 2017). This will be my 4th time on the Tour.  
    I am looking forward to the great company, long hills, strong headwinds, and the inevitable rain!!`,
  },
  {
    riderName: 'Ewan Fraser',
    position: 'Rider',
    ridingFor: 'Mark Murtagh',
    eow: '16/05/2014',
    image: '/images/profile/ewan_fraser',
    sponsorName: 'Ewan',
    justGiving: 'https://www.justgiving.com/page/ewan-fraser-1704197949556',
    bio: `My name is Ewan Fraser, I'm a serving officer based in Dumfries, and I currently deliver Operational Safety and First Aid Training across Scotland.
    This will be my fourth year supporting Care of Police Survivors (COPS), and this year I have the honour of cycling in memory of Police Constable Mark Murtagh.  
    Mark, based at Galashiels Police Office, died on 16th May 2014, while travelling to his work when he was involved in a road traffic collision.
    He was survived by his wife and son.
    Please help our chapter raise the much needed funds for COPS, so they can continue to support the families of fallen officers, though difficult times.`,
  },
  {
    riderName: 'Alan Joyce',
    position: 'Rider',
    ridingFor: 'Neil McCover',
    eow: '08/10/2010',
    image: '/images/profile/alan_joyce',
    sponsorName: 'Alan',
    justGiving: 'https://www.justgiving.com/page/alan-joyce-1705496099486',
    bio: ``,
  },
  {
    riderName: 'Mark McFern',
    position: 'Rider',
    ridingFor: 'Joseph Drake',
    eow: '11/08/1967',
    image: '/images/profile/mark_mcfern',
    sponsorName: 'Mark',
    justGiving: 'https://www.justgiving.com/page/mark-mcfern-1705592242257',
    bio: `I'm Mark McFern and I am a Serving Police Officer with Police Scotland. I have almost 24 years Police Service and I am currently working within the Custody Suite at Stranraer Police Station.
    I am a newby to the Police Unity Tour.  I always said I would take part before I was 50. So, this year is the year.
    I am honoured to be cycling in memory of Constable Jospeh Drake, Stirling and Clackmannan Constabulary who was tragically killed on 11 August 1967 as he attempted to stop a stolen lorry at a roadblock he had set up with his colleague.
    COPS is a wonderful charity which helps the families of Serving Officers when the unimaginable happens.  No one should ever leave home for work and never return.
    I am a Dad to Beth and Ruari, and it comforts me knowing that should I ever pay the ultimate sacrifice at work, then I know COPS will be there to offer support to my kids.
    I am truly honoured to be cycling as part of the Scotland Chapter for Police Unity Tour 2024.`,
  },
  {
    riderName: 'Richard North',
    position: 'Rider',
    ridingFor: 'Paul North',
    eow: '17/03/1987',
    image: '/images/profile/richard',
    sponsorName: 'Richard',
    justGiving: 'https://www.justgiving.com/fundraising/Richard-North78',
    bio: ``,
  },
  {
    riderName: 'Jane Dunbar',
    position: 'Rider',
    ridingFor: 'Edward Bell',
    eow: '09/08/1981',
    image: '',
    sponsorName: 'Jane',
    justGiving: 'https://www.justgiving.com/page/jane-dunbar-1706179542103',
    bio: ``,
  },
  {
    riderName: 'Julia McKie',
    position: 'Rider',
    ridingFor: 'George Hughes',
    eow: '22/02/1979',
    image: '/images/profile/jules_mckie',
    sponsorName: 'Julia',
    justGiving:
      'https://www.justgiving.com/page/julia-and-iain-mckie-1706453184342',
    bio: `I am Julia McKie, a retired police officer from Police Scotland. I retired in November 2023, having served within V Division. 
    This is my third Unity Tour and I will be riding in honour of George Hughes from Fife Constabulary. Sergeant Hughes died on the 22nd February 1979, aged 42. He died when his vehicle was involved in a collision on his way to take up duty.
    His wife who was in the vehicle also died, they were survived by their 3 children. 
    I'm looking forward to cycling with this a chapter and raising money for such a great charity. Having been a serving Police Officer, COPS is close to all our hearts and we all appreciate how much comfort and support they provide to the families of those who have died on duty. 
    `,
  },
  {
    riderName: 'Iain McKie',
    position: 'Rider',
    ridingFor: 'Richard Gray',
    eow: '06/05/2007',
    image: '/images/profile/iain_mckie',
    sponsorName: 'Iain',
    justGiving:
      'https://www.justgiving.com/page/julia-and-iain-mckie-1706453184342',
    bio: `Hi my name is Iain McKie and I retired from the Police in January 2017 having served with Grampian Police, Dumfries and Galloway Constabulary and Police Scotland. 
    This is the 7th time I've taken part in the UK Police Unity Tour. This year I'm riding in memory of PC Richard Gray of West Mercia Police, 
    who was murdered on 6 May 2007 when he was shot while trying to rescue two colleagues who were being held hostage by an armed man. 
    I'm raising money for Care of Police Survivors (COPS), who are a fantastic charity that provide peer support to the families of officers who have died while on duty.    
    Any donation you can make would be greatly appreciated.`,
  },
  {
    riderName: 'Martin Burnett',
    position: 'Rider',
    ridingFor: 'Roy Buggins',
    eow: '03/09/2019',
    image: '/images/profile/martin',
    sponsorName: 'Martin',
    justGiving: 'https://www.justgiving.com/page/martin-burnett-1707324817694',
    bio: ``,
  },
  {
    riderName: 'Steve Johnson',
    position: 'Rider',
    ridingFor: 'Bill Barker',
    eow: '20/11/2009',
    image: '',
    sponsorName: 'Steve',
    justGiving: 'https://www.justgiving.com/page/steve-johnson-1713813603976',
    bio: ``,
  },
  {
    riderName: 'Ian Dawes',
    position: 'Rider',
    ridingFor: 'John Hewitt',
    eow: '16/06/2020',
    image: '/images/profile/ian',
    sponsorName: 'Ian',
    justGiving: 'https://www.justgiving.com/page/ian-dawes-1714640310846',
    bio: ``,
  },
  {
    riderName: 'Alix Brodie',
    position: 'Rider',
    ridingFor: 'Kenneth Brodie',
    eow: '08/01/1997',
    image: '/images/profile/alix_brodie',
    sponsorName: 'Alix',
    justGiving: 'https://www.justgiving.com/page/alix-brodie-1715287076383',
    bio: `This year I have the honour of cycling in memory of my Dad, PC Kenneth Brodie of Dumfries and Galloway Constabulary. 
    My Dad died on duty on the 8th of January 1997, in an RTC whilst on duty. 
    This will be my 5th year cycling in the Police Unity Tour after saying I'd only ever do it once in 2019 
    but I continue to cycle in memory of my Dad and all the police officers that have died on duty to raise funds for COPS.`,
  },
  {
    riderName: 'Alan Watson',
    position: 'Rider',
    ridingFor: 'Mark Davey',
    eow: '29/08/1995',
    image: '/images/profile/alan_watson',
    sponsorName: 'Alan',
    justGiving: 'https://www.justgiving.com/page/alan-watson-1714420676897',
    bio: `I retired last year after 41 years service in both Dumfries and Galloway Constabulary
    and the Ministry of Defence Police.
    I'm returning to cycling after about a 20 year absence and was looking for a
    worthwhile charity to support. My old Dumfries colleagues have supported Care of
    Police Survivors over many years and I decided to join them this year.
    The Unity Tour is a formidable challenge but well worth the effort to help support the
    bereaved families of deceased colleagues.
    I will be riding in memory of PC Mark Davey, Grampian Police, who tragically died as
    a result of a Road Traffic Collision. Mark was a Traffic officer, as was I for many
    years.`,
  },
  {
    riderName: 'Colin Cravens',
    position: 'Rider',
    ridingFor: 'Malcolm Whigham',
    eow: '16/08/2007',
    image: '/images/profile/colin',
    sponsorName: 'Colin',
    justGiving: 'https://www.justgiving.com/page/colin-cravens-1715680624420',
    bio: `Hi, my name is Colin Cravens and I'm a retired police officer.
    I still have a vested interest in Police Scotland as my daughter Kirsty is a
    serving Police Sergeant.
    This will be my 5th time on the tour.`,
  },
  {
    riderName: 'Gavin Kelly',
    position: 'Rider',
    ridingFor: 'Alasdair Ferry',
    eow: '08/10/2003',
    image: '/images/profile/gavin_kelly',
    sponsorName: 'Gavin',
    justGiving: 'https://www.justgiving.com/page/gavin-kelly-1716056135329',
    bio: `I have been a serving officer for 23 years, formerly of Strathclyde police and now of Police Scotland, having served in several different roles throughout the years.
    I took up cycling about 10 years ago as a leisure activity, I was previously a runner but cycling looked so much easier so I gave it a go....I was wrong, it's not that much easier!!!
    Since then I have participated in several triathlon events and regularly put the miles in on my bike.
    I recently learned about the UK Police Unity Tour on behalf of COPS (Care of Police Survivors). 
    I just thought to myself that the tour is such a great idea to raise funds for surviving families. 
    It made complete sense for me to take part and do my bit to help out as much as I can, due to my interest in cycling and my Police Service, 
    so I entered the tour and I am in the process of raising as much funds as I can for those in need`,
  },
  {
    riderName: 'Adam Watret',
    position: 'Rider',
    ridingFor: 'Lewis Fulton',
    eow: '17/06/1994',
    image: '/images/profile/adam',
    sponsorName: 'Adam',
    justGiving: 'https://www.justgiving.com/',
    bio: ``,
  },
  {
    riderName: 'Alister Johnson',
    position: 'Rider',
    ridingFor: 'Melanie Igoe',
    eow: '30/05/1995',
    image: '',
    sponsorName: 'Alister',
    justGiving: 'https://www.justgiving.com/page/alister-johnson-1717155534784',
    bio: ``,
  },
  {
    riderName: 'Lee Dawes',
    position: 'Support',
    ridingFor: 'Kenneth Acheson',
    eow: '02/04/1981',
    image: '',
    sponsorName: 'Lee',
    justGiving: '',
    bio: ``,
  },
  {
    riderName: 'Joe Dignan',
    position: 'Support',
    ridingFor: 'Karen Balfour',
    eow: '05/01/2006',
    image: '',
    sponsorName: 'Joe',
    justGiving: '',
    bio: ``,
  },
  {
    riderName: 'Laurie Irving',
    position: 'Support',
    ridingFor: 'Kirtsy Allan',
    eow: '02/03/2007',
    image: '',
    sponsorName: 'Laurie',
    justGiving: '',
    bio: ``,
  },
  {
    riderName: 'Mary McCreadie',
    position: 'Support',
    ridingFor: 'David Traill',
    eow: '29/11/2013',
    image: '',
    sponsorName: 'Mary',
    justGiving: '',
    bio: ``,
  },
];
