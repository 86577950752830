import { useEffect } from 'react';

const Privacy = () => {
  useEffect(() => {
    document.title = 'Police Unity Tour Scotland - Privacy Policy';
    window.scrollTo({
      top: 0,
    });
  }, []);

  return (
    <>
      <h1 className="text-2xl md:text-4xl font-bold text-blue-900">
        Police Unity Tour Scotland.
      </h1>
      <h2 className="text-xl md:text-3xl font-bold text-indigo-700">
        Privacy Policy.
      </h2>
      <h3 className="mt-6 text-xl font-semibold">Introduction</h3>
      <p>
        Your privacy is important to us. This privacy statement sets out how
        we’ll protect your data, in line with the current legislation, through
        appropriate organisation and technical security measures/processes. It
        also describes your rights in relation to that data.
      </p>
      <p className="mt-2">
        We reserve the right to make changes to this statement as necessary.
        When we do so we’ll make previous versions available on request so you
        can see what these changes are. If any such changes alter key aspects of
        the notice or its meaning we’ll notify you of those changes in advance.
        We encourage you to occasionally review this page for information on our
        latest practices.
      </p>
      <p className="mt-2">
        This privacy notice specifically covers eligibility and communications.
      </p>
      <h3 className="mt-6 text-xl font-semibold">
        How we collect personal data
      </h3>
      <p>
        You can give us your personal data in a number of ways. For example,
        when your register yourself to ride on the tour through our website.
      </p>
      <p className="mt-2">
        When we collect data from you we’ll ensure it’s adequate for the purpose
        and never excessive.
      </p>
      <h3 className="mt-6 text-xl font-semibold">
        How we use your personal data
      </h3>
      <p>
        <span className="font-semibold">Eligibility verification</span> - when
        you sign up to ride with us we use the information you provide to us to
        ensure you are eligible to take part in the tour.
      </p>
      <p className="mt-2">
        <span className="font-semibold">Tour logistics</span> - we use the data
        you provide to help us with the planning and organisation of the tour
        logistics.
      </p>
      <p className="mt-2">
        <span className="font-semibold">Disclosures required by law</span> - the
        law can require the disclosure of information for various reasons, in
        such circumstances we must comply with those requests.
      </p>
      <p className="mt-2">
        <span className="font-semibold">Activities and fund raising</span> - we
        may use your data to keep you informed of our activities and for
        occasional fundraising appeals.
      </p>
      <h3 className="mt-6 text-xl font-semibold">Sharing</h3>
      <p>
        In the course of business we may share information with carefully
        selected organisations we engage with to provide certain services. These
        include:
      </p>
      <ul className="pl-10 pt-2 list-disc">
        <li>Data validation services</li>
        <li>
          Data storage services, including cloud storage and data management
        </li>
        <li>Event organisers</li>
        <li>Financial organisations such as banks and building societies</li>
        <li>Hotels and restaurants we use during the tour</li>
        <li>Apparel companies</li>
      </ul>
      <h3 className="mt-6 text-xl font-semibold">Protecting your data</h3>
      <p>
        We ensure the highest levels of security, both technical and
        organisational when collecting and processing data, and when your data
        is in transit. This is regularly reviewed to ensure those measures
        remain effective and up-to-date with current technologies.
      </p>
      <h3 className="mt-6 text-xl font-semibold">Retention of data</h3>
      <p>
        We will retain these records indefinitely/for life unless you object or
        request to engage your right to be forgotten.
      </p>
      <h3 className="mt-6 text-xl font-semibold">
        Cookies and other form of online tracking
      </h3>
      <p>
        When visiting our website, we may use features which collect your IP
        address and data on which pages you are visiting on our site. We may
        also issue cookies to enable certain features to function properly,
        track traffic on our website or improve user experience in other ways.
        Cookies place certain information on your device to allow this to
        happen. This allows us to tailor your experience and process payment
        information.
      </p>
      <h3 className="mt-6 text-xl font-semibold">
        Categories of data we collect
      </h3>
      <ul className="pl-10 pt-2 list-disc">
        <li>
          Personal data from tour riders: name, email address, mobile number,
          relationship to police, jersey size, address, payment details, dietary
          requirements.
        </li>
        <li>
          Personal data from other individuals: name of fallen officer, police
          force of fallen officer.
        </li>
      </ul>
      <h3 className="mt-6 text-xl font-semibold">Rights</h3>
      <p>
        Current legislation provides the following rights for individuals in
        relation to their personal data:
      </p>
      <ol className="pl-10 pt-2 list-decimal">
        <li>The right to be informed</li>
        <li>The right of access</li>
        <li>The right to rectification</li>
        <li>The right to erasure</li>
        <li>The right to restrict processing</li>
        <li>The right to data portability</li>
        <li>The right to object</li>
        <li>Rights in relation to automated decision making and profiling</li>
      </ol>
      <p className="mt-2">
        To find out more about what these rights mean for you, refer to the
        Information Commissioner’s website: https://ico.org.uk/
      </p>
      <h3 className="mt-6 text-xl font-semibold">Contact</h3>
      <p>
        If you have any questions regarding this statement or you wish to
        discuss your data, you can contact us by emailing the Chapter Lead at{' '}
        <a
          href="mailto:chapterlead@unitytour.scot"
          className="text-blue-900 font-semibold tracking-wider"
        >
          chapterlead@unitytour.scot
        </a>
      </p>
    </>
  );
};

export default Privacy;
