import { UserCircleIcon } from '@heroicons/react/solid';
import ReactHtmlParser from 'html-react-parser';

const ProfileCard = ({ rand, rider }) => {
  const {
    riderName,
    position,
    image,
    bio,
    sponsorName,
    justGiving,
    ridingFor,
    eow,
  } = rider;
  return (
    <div className="flex flex-col">
      <div className="flex">
        <div className="flex items-center justify-center w-16 h-16 rounded-full overflow-hidden border-2 border-indigo-800 border-opacity-60 shadow-lg">
          {image !== '' ? (
            <picture>
              <source srcSet={`${image}.webp`} />
              <img src={`${image}.jpg`} alt={riderName} />
            </picture>
          ) : (
            <UserCircleIcon className="text-blue-800 opacity-60" />
          )}
        </div>
        <div className="flex flex-col items-center flex-grow">
          <p className="text-indigo-800 font-bold text-xl">{riderName}</p>
          <p className="text-md text-gray-500">{position}</p>
        </div>
      </div>
      <div className="flex flex-grow flex-col">
        <div className="flex flex-grow flex-col justify-between">
          <p className="pt-2">{ReactHtmlParser(bio)}</p>
          <div className="mt-6 flex flex-col items-center">
            <p className="text-gray-500 text-center">
              in memory of:{' '}
              <span className="font-bold text-indigo-800">{ridingFor}</span>
            </p>
            <p className="text-gray-500 text-center text-sm">
              EOW: <span className="text-indigo-800">{eow}</span>
            </p>
            {justGiving ? (
              <a
                href={justGiving}
                target="_blank"
                rel="noreferrer"
                className="mt-6 inline-block w-48 text-center px-6 py-2 rounded-xl shadow-lg border-2 border-indigo-800  bg-indigo-800 hover:bg-indigo-700 hover:border-indigo-700 focus:outline-none focus:ring focus:ring-offset-2 focus:ring-blue-800 focus:ring-opacity-50 active:bg-indigo-900 transform transition text-white tracking-wider font-semibold"
              >
                Sponsor {sponsorName}
              </a>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileCard;
