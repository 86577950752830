import { useEffect, useState, useRef } from 'react';
import { Dialog } from '@headlessui/react';
import { XIcon } from '@heroicons/react/solid';

import GalleryImage from '../components/GalleryImage';

const galleryData = require('../data/gallery');

const Gallery = () => {
  useEffect(() => {
    document.title = 'Police Unity Tour Scotland - Gallery';
  }, []);

  let [isOpen, setIsOpen] = useState(false);
  let dialogCloseButton = useRef(null);
  let [bigPicture, setBigPicture] = useState(null);
  let [fallbackPicture, setFallbackPicture] = useState(null);

  const openPhoto = (imageName) => {
    setBigPicture(`/images/large/webp/${imageName}_large.webp`);
    setFallbackPicture(`/images/medium/${imageName}_medium.jpg`);
    setIsOpen(true);
  };

  const galleryItems = galleryData.map((item, i) => (
    <GalleryImage
      key={i}
      size={item.size}
      shape={item.shape}
      image={item.image}
      alt={item.alt}
      title={item.title}
      position={item.position}
      openPhoto={openPhoto}
    />
  ));

  return (
    <>
      <Dialog
        initialFocus={dialogCloseButton}
        open={isOpen}
        onClose={() => setIsOpen(false)}
        className="fixed z-10 inset-0 overflow-y-auto"
      >
        <div className="flex items-center justify-center min-h-screen">
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-60" />
          <div className="px-4 pt-2 pb-4 relative bg-black rounded max-w-xl lg:max-w-2xl mx-auto">
            <Dialog.Title className="flex justify-end text-gray-200">
              {
                <button ref={dialogCloseButton} className="outline-none">
                  <XIcon
                    className="w-6 h-6 cursor-pointer"
                    onClick={() => setIsOpen(false)}
                  />
                </button>
              }
            </Dialog.Title>
            <picture>
              <source srcSet={bigPicture} />
              <img
                src={fallbackPicture}
                alt=""
                className="mt-4 w-full h-auto max-h-full"
              />
            </picture>
          </div>
        </div>
      </Dialog>
      <h1 className="text-2xl md:text-4xl font-bold text-blue-900">
        Police Unity Tour Scotland.
      </h1>
      <h2
        className="text-xl md:text-3xl font-bold text-indigo-700"
        onClick={() => setIsOpen(true)}
      >
        Gallery.
      </h2>
      <div className="mt-6 grid grid-flow-row-dense gap-2 grid-cols-3 sm:grid-cols-8">
        {galleryItems}
      </div>
    </>
  );
};

export default Gallery;
